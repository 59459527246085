import { defineStore } from 'pinia'
import { auth, functions } from '@/main'
import { deleteUser, signOut, updateEmail, updateProfile } from 'firebase/auth'
import router from '@/router'
import { notify } from '@kyvg/vue3-notification'
import { httpsCallable } from 'firebase/functions'

export const useAuthStore = defineStore('authStore', {
  state: () => {
    return {
      user: null,
      error: null
    }
  },
  persist: true,
  getters: {
    isLoggedIn: (state) => state.user !== null,
    userError: (state) => state.error
  },
  actions: {
    /**
     *
     * @param data
     */
    async logoutUser(auto) {
      return signOut(auth).then(() => {
        if(!auto) {
          notify({
            title: 'Successfully logged out.',
            text: '',
            type: 'info'
          })
          router.push('/')
        }
        this.user = null
        this.error = null
        return true
      }).catch(error => {
        notify({
          title: 'Something went wrong!',
          text: error,
          type: 'error'
        })
        this.error = error
        return false
      })
    },
    /**
     *
     * @param data
     */
    async createAccount(user, name, email) {
      return await updateProfile(user, {
        displayName: name
      }).then(async () => {
        await updateEmail(auth.currentUser, email).catch(error => {
          console.log('Email registration failed')
          console.log(error)
          deleteUser(user)
          throw {
            title: 'Email already used!',
            text: `An account has already been registered with ${email}. Try to sign in instead`
          }
        })
        this.user = user ? user : null
        this.error = null
        notify({
          title: `Hi, ${name}!`,
          text: 'Enjoy your free trial.',
          type: 'success'
        })
        router.push('/upload')
        return true
      }).catch(error => {
        notify({
          title: error.title ? error.title : 'Something went wrong!',
          text: error.text ? error.text : error,
          type: 'error'
        })
        this.user = null
        this.error = error
        router.push('/signin')
        return false
      })
    },
    /**
     *
     * @param data
     */
    async logIn(user) {
      const checkCustomClaims = httpsCallable(functions, 'checkCustomClaims')
			const checkCustomClaimsResults = await checkCustomClaims({}).catch(error => {
				console.log('checkCustomClaims error')
				console.log(error)
				notify ({
					title: 'Membership check error!',
					text: error,
          type: 'error'
				})
        this.user = null
        this.error = error
        return false
			})
      if(!checkCustomClaimsResults.data) {
        // Custom Claims added. Log out user and notify to log in again
        this.logoutUser(true)
        notify ({
					title: 'Membership updated!',
					text: 'Please log in again',
          type: 'success'
				})
        router.go(0)
        return
      } else {
        // all good
        this.user = user ? user : null
        this.error = null
        notify ({
					title: 'Welcome back!',
					text: `You have ${5 - checkCustomClaimsResults.data.numberOfUploads} uploads left in your ${checkCustomClaimsResults.data.membership} membership`,
          type: 'success'
				})
        router.push('/upload')
      }
      return
    }
  }
})
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import Notifications from '@kyvg/vue3-notification'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCircleXmark as fasCircleXmark,
  faUserShield as fasUserShield,
  faCircleNotch as fasCircleNotch } from '@fortawesome/free-solid-svg-icons'
import { faCircleRight as farCircleRight, 
  faHandPointRight as farHandPointRight, 
  faPaperPlane as farPaperPlane,
  faHand as farHand } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import 'font-awesome-animation/css/font-awesome-animation.min.css'
import { firebaseConfig } from './firebase-config'
import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getAuth } from 'firebase/auth'
import { getStorage } from 'firebase/storage'
import { getFunctions } from 'firebase/functions'
import './assets/fonts/stylesheet.css'
import './assets/style.stylus'
import vue3videoPlay from 'vue3-video-play'
import 'vue3-video-play/dist/style.css'

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)
const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)
const auth = getAuth(app)
const storage = getStorage(app)
const functions = getFunctions(app)

library.add(fasCircleXmark, farCircleRight, farHandPointRight, farPaperPlane, farHand, fasUserShield, fasCircleNotch )

createApp(App).directive('visible', (el, binding) => { 
  el.style.visibility = !binding.value ? 'hidden' : 'visible' 
}).use(pinia).use(Notifications).use(vue3videoPlay).use(router).component('font-awesome-icon', FontAwesomeIcon).mount('#app')

export { auth, storage, analytics, functions }
import { createRouter, createWebHistory } from 'vue-router'
import { useAuthStore } from '@/stores'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      component: () => import('../views/HomePage.vue'),
      name: 'Copyrighter'
    },
    {
      path: '/faq',
      component: () => import('../views/FaqPage.vue'),
      name: 'Copyrighter - How it works'
    },
    {
      path: '/termsandconditions',
      component: () => import('../views/TermsAndConditions.vue'),
      name: 'Copyrighter - Terms and Conditions'
    },
    {
      path: '/register',
      component: () => import('../views/RegisterPage.vue'),
      name: 'Copyrighter - Register'
    },
    {
      path: '/signin',
      component: () => import('../views/WelcomeBack.vue'),
      name: 'Copyrighter - Welcome back'
    },
    {
      path: '/upload',
      component: () => import('../views/UploadPage.vue'),
      meta: {
        requiresAuth: true
      },
      name: 'Copyrighter - Upload your content'
    },
  ],
})

router.beforeEach((to, from, next) => {
  document.title = to.name || 'Copyrighter'
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
  const currentUser = useAuthStore().isLoggedIn

  if(requiresAuth && !currentUser) {
    next({ path: '/register', query: { redirect: to.fullPath } })
  } else if(!requiresAuth && currentUser) {
    next()
  } else if(!requiresAuth && !currentUser) {
    next()
  } else {
    next()
  }
})

export default router
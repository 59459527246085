<template>
  <notifications classes='custom-notif' :duration=5000 />
  <div id='body'>
    <div id='header'>
      <img id='logo' class='logo' @click.prevent='router.push("/")' />
      <router-link class='router-link btn btn-faq' to='/faq'>
        HOW IT WORKS
      </router-link>
      <router-link v-if='!store.isLoggedIn' class='router-link btn btn-signin' to='/signin'>
        SIGN IN
      </router-link>
      <div v-if='store.isLoggedIn' @click.prevent='store.logoutUser()' class='btn btn-logout'>
        LOG OUT
      </div>
    </div>
    <hr>
    <div id='app'>
      <router-view />
    </div>
    <hr>
    <div id='footer'>
      <span class='copyrights'>Copyright © 2022 - Indieframe</span>
      <span class='termsconditions'>Privacy Policy - <a href="termsandconditions">Terms&amp;Conditions</a></span>
    </div>
  </div>
</template>

<script setup>
  import { useAuthStore } from '@/stores'
  import { onAuthStateChanged } from '@firebase/auth'
  import { auth } from './main'
  import router from './router'

  const store = useAuthStore()

  onAuthStateChanged(auth, user => {
    if(user) {
      store.user = user
      store.error = null
    } else {
      store.user = null
      store.error = 'User is logged out'
    }
  })
</script>

<style scoped lang='stylus'>

  #body {
    padding: 20px;
  }

  #header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  #footer {
    color: var(--red-color);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 12px;
    .termsconditions {
      text-align: right;
    }
  }

  hr {
    border: 1px solid red;
    margin: 20px 0px;
  }

  .router-link {
    color: inherit;
    text-decoration: none;
  }
  
  #app {
    text-align: center;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  a {
    color: var(--red-color);
  }

  .logo {
    padding: 20px 160px;
    background-image: url('~@/assets/logo.png');
    background-size: contain;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
  }

  .btn {
    line-height: 26px;
    height: 26px;
    text-align: center;
    width: 85px;
    min-width: 85px;
    cursor: pointer;
    font-size: 12px;
    margin: 5px 0px;
  }

  .fa-signin, .fa-logout {
    margin-left: 5px;
  }

  .btn-signin, .btn-logout, .btn-faq {
    color: var(--red-color);
    transition: all .5s;
    position: relative;
    background-color: var(--white-color);
    border-radius: var(--corner-radius);
    border: 1px solid var(--red-color);
    font-weight: bold;
    &:hover{
      color: var(--white-color);
      background-color: var(--red-color);
      font-weight: normal;
      border: 1px solid var(--transparent-color);
      .fa-signin {
       
      }
      .fa-logout {
        
      }
    }
  }

  .btn-faq {
    width: 120px;
    margin-left: auto;
    margin-right: 10px;
  }

  

  @media only screen and (max-width: 500px) {
    .logo {
      padding: 20px 20px;
      background-image: url('~@/assets/icon.png');
    }
  }

  
</style>
